export const HOMEPAGE_ROUTE = '/';
export const HOMEPAGE_ROUTE_HOW_IT_WORKS = '/#howitworks';
export const FIND_TUTORS_ROUTE = '/tutors';
export const FIND_STUDENTS_ROUTE = '/students';
export const BECOME_TUTOR_ROUTE = '/become-tutor';
export const ABOUT_ROUTE = '/about';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const REGISTER_ROUTE = '/register';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const COOKIE_POLICY_ROUTE = '/cookie-policy';
export const CONTACT_ROUTE = '/contact'
export const DEFAULT_ROLE_ROUTE = '/user-details';
export const NOT_FOUND_ROUTE = '/not-found';

export const STUDENT_ROUTES = {
  PRIVATE: {
    STUDENT_DASHBOARD: '/app/dashboard',
    DASHBOARD: '/app',
    CLASSROOM: '/app/classroom',
    MESSAGES: '/app/messages/:id',
    MYTUTORS: '/app/tutors',
    TUTORS_PAYMENT: '/app/tutors/:uuid',
  },
  PUBLIC: {
    PROFILE: '/student/[slug]',
  },
};

export const TUTOR_ROUTES = {
  PRIVATE: {
    TUTOR_DASHBOARD: '/app/dashboard',
    DASHBOARD: '/app',
    SCHEDULE: '/app/schedule',
    AVAILABILITY: '/app/availability',
    MESSAGES: '/app/messages/:id',
    STUDENTS: '/app/students',
    REVIEWS: '/app/reviews',
  },
  PUBLIC: {
    PROFILE: '/tutor/[slug]',
    AVAILABILITY: '/tutor/[slug]/availability',
    REVIEWS: '/tutor/[slug]/reviews',
  },
};

export const COMMON_ROUTES = {
  PRIVATE: {
    MESSAGES: '/app/messages/:target/:id/:state',
  },
};
